import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import { faCopy } from "@fortawesome/pro-light-svg-icons";

import { useInvitationLink } from "../../../../hooks/booking/useInvitationLink";
import { useToaster } from "../../../../hooks/common/useToaster";
import { useGames } from "../../../../hooks/swr/useGames";

import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { NothingToShow } from "../../../../components/NothingToShow";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { GameContent } from "../../../game/components/userOverview/GameContent";

import { ScoreManager } from "./ScoreManager";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog";

interface Props {
  isUpcoming?: boolean;
}

export const Bookings = ({ isUpcoming = false }: Props) => {
  const history = useHistory();
  const { toastError, toastSuccess } = useToaster();
  const [matchId, setMatchId] = useState<string>();
  const [hasScoresUnsaved, setScoresUnsaved] = useState<boolean>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [externalServiceId, setExternalServiceId] = useState<string>();
  const [showInviteDialog, setShowInviteDialog] = useState<boolean>();
  const invitationLink = useInvitationLink(externalServiceId ?? "");

  const page = 0;
  const rowsToShow = 100;

  useEffect(() => {
    if (!showDialog) setShowConfirmationDialog(false);
  }, [showDialog]);

  const {
    games,
    isLoading: loadingGames,
    mutate: refreshGames,
  } = useGames(isUpcoming, page, rowsToShow);

  const handleEditOpen = async (matchId: string) => {
    setMatchId(matchId);
    setShowDialog(true);
  };

  // Invitaion link set for the pop up
  const handleInviteFriendsClick = (
    isShow: boolean,
    externalServiceId: string,
  ) => {
    setShowInviteDialog(isShow);
    setExternalServiceId(externalServiceId);
  };

  const handleCopyInviteLink = async () => {
    try {
      if (invitationLink) {
        const link = window.location.origin + "/#" + invitationLink;
        await navigator.clipboard.writeText(link);
        toastSuccess.linkCopied();
      }
    } catch (err) {
      toastError.generateInvitationLinkFailed();
    }
  };

  const handleReschedule = (facilityId: string, bookingId: string) => {
    history?.push(`/reschedule/${facilityId}/${bookingId}`);
  };

  if (loadingGames) return <ProgressSpinner />;

  return !games?.length ? (
    <NothingToShow
      translationId={
        isUpcoming
          ? "profile-settings.no-upcoming-bookings"
          : "profile-settings.no-player-games"
      }
    >
      <Link to="/booking">
        <FormattedMessage id="common.book-to-play" />
      </Link>
    </NothingToShow>
  ) : (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      {games?.map(game => (
        <GameContent
          showUpcoming={isUpcoming}
          game={game}
          key={game.id}
          handleInviteFriend={handleInviteFriendsClick}
          handleTeams={d => {
            setShowDialog(true);
            handleEditOpen(d);
          }}
          handleReschedule={handleReschedule}
          refreshData={refreshGames}
        />
      ))}

      <Dialog
        onHide={() => setShowInviteDialog(false)}
        visible={showInviteDialog}
      >
        <h4 className="mb-4">
          <FormattedMessage id="booking-info.invitation-label" />
        </h4>
        <div className="flex flex-col items-center justify-end gap-2 sm:flex-row-reverse">
          <Button
            className="-ml-2 cursor-copy rounded-l-none p-3.5"
            icon={faCopy}
            buttonType="button"
            type="primary"
            onClick={handleCopyInviteLink}
          />
          {invitationLink && (
            <input
              className="w-full max-w-80 border border-gray-400 p-3 opacity-50"
              type="text"
              value={window.location.origin + "/#" + invitationLink}
              readOnly
            />
          )}
        </div>
      </Dialog>

      {matchId && (
        <Dialog
          onWillHide={() => {
            if (hasScoresUnsaved) {
              setShowConfirmationDialog(true);
            }
            return !hasScoresUnsaved;
          }}
          onHide={() => {
            setShowDialog(false);
          }}
          visible={showDialog}
        >
          <ScoreManager
            matchId={matchId}
            onHasUnsavedChanges={hasUnsavedChanges =>
              setScoresUnsaved(hasUnsavedChanges)
            }
            onLocked={() => {
              refreshGames();
            }}
          />
        </Dialog>
      )}
      <UnsavedChangesDialog
        visible={showConfirmationDialog}
        onHide={() => setShowConfirmationDialog(false)}
        onAbortClick={() => setShowDialog(false)}
        onCancelClick={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};
