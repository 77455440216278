import type { OpenBooking } from "../models/OpenBookings";
import type { Order } from "../models/Order";
import type { IBooking } from "../modules/checkout/models/Booking";
import type { IActivity } from "../modules/game/models/Activity";
import type { Series } from "../modules/game/models/Series";
import { User } from "../modules/player/models/User";

/**
 * Player paths
 */
export const getMyProfilePath = () => "/me" as const;

export const getMyProfileTabPath = (tabIndex: number) =>
  `/me/${tabIndex}` as const;

export const getProfilePath = (userId: User["id"]) =>
  `/profile/${userId}` as const;

export const getHomePath = () => `/` as const;

export const getBookingPath = () => "/booking" as const;

export const getVenuesPath = () => `/venues` as const;

export const getVenuePath = (venueId: string, tab?: string) =>
  `/venues/${venueId}${tab ? `?tab=${tab}` : ""}` as const;

export const getFAQPath = () => "https://faq.court22.com" as const;

export const getOpenBookingLinkPath = (openBookingId: OpenBooking["id"]) =>
  `/open-booking/${openBookingId}` as const;

/**
 * Admin paths
 */
export const getCheckoutPath = (orderId: Order["id"]) =>
  `/checkout/${orderId}` as const;

export const adminGetPlayerPath = (userId: User["id"]) =>
  `/admin/players/${userId}` as const;

export const adminGetPlayersPath = () => `/admin/players` as const;

export const adminGetHomePath = () => `/admin` as const;

export const adminGetActivitiesPath = () => "/admin/activities" as const;

export const adminGetSettingsPath = () => "/admin/settings" as const;

export const adminGetActivitesCreateEventPath = (startDate?: string) =>
  startDate
    ? (`/admin/activities/event/new?startDate=${startDate}` as const)
    : ("/admin/activities/event/new" as const);

export const adminGetActivitesEventPath = (eventId: IActivity["id"]) =>
  `/admin/activities/event/${eventId}` as const;

export const adminGetActivitesSerieMatchesPath = (serieId: IActivity["id"]) =>
  `/admin/activities/serie/${serieId}/matches` as const;

export const adminGetActivitesSeriesPath = (seriesId: Series["id"]) =>
  `/admin/activities/serie/${seriesId}` as const;

export const adminGetFAQPath = () => "https://court-admin.kb.kundo.se" as const;

export const adminGetBookingPath = () => "/admin/booking" as const;

export const adminGetMobilePath = () => "/admin/mobile" as const;

export const adminGetMobileBookingPath = () => "/admin/mobile/booking" as const;

export const adminGetMobileBookingFormPath = () =>
  "/admin/mobile/booking/form" as const;

export const adminGetMobileBookingEditPath = (bookingId: IBooking["id"]) =>
  `/admin/mobile/edit-booking/${bookingId}` as const;

export const adminGetMobileBookingsPath = () =>
  "/admin/mobile/bookings" as const;

export const adminGetMobileActivitiesPath = () =>
  "/admin/mobile/activities" as const;

export const adminGetMobileDashboardPath = () =>
  "/admin/mobile/dashboard" as const;

export const adminGetMobileBookingReschedulePath = (
  facilityId: string,
  bookingId: string,
) => `/admin/mobile/booking/reschedule/${facilityId}/${bookingId}` as const;

export const adminGetSuperAdminFacilityPath = (facilityId: string) =>
  `/superadmin/facility/${facilityId}` as const;
