import { useMemo, useRef, useState, useTransition } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faArrowDownArrowUp,
  faBarsFilter,
  faCheck,
  faEllipsis,
  faSort,
  faSortAlphaAsc,
  faSortAlphaDesc,
  faSortAsc,
  faSortDesc,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "@mui/base/Dropdown";
import { MenuButton } from "@mui/base/MenuButton";
import {
  type Row,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { InputSwitch } from "primereact/inputswitch";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

import type { UnpaidObject } from "../../../../../models/unpaid";

import { adminGetPlayerPath } from "../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useSelectedFacility } from "../../../../../hooks/swr/useSelectedFacility";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import { useFacilitySportTypes } from "../../../../../hooks/useFacilitySportTypes";

import {
  getUnpaidPagedResult,
  getUnpaidTotalCount,
  markUnpaidObjectAsPaid,
} from "../../../../../services/facilityStatisticsService";

import { Button } from "../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { Menu, MenuItem } from "../../../../../components/Menu";
import { SportTypeTranslatedName } from "../../../../../components/SportTypeTranslatedName";
import {
  Table,
  TableCurrencyCell,
  TableDateCell,
  TableDefaultRowComponent,
} from "../../../../../components/Table";
import { SelectInput } from "../../../../../components/inputs/SelectInput";

import { luxonDateFormat } from "../../../../../utils/dateFormats";

type Data = UnpaidObject;

const PAGE_SIZE = 10;

const columnHelper = createColumnHelper<Data>();

export const Unpaid = () => {
  const { formatMessage } = useIntl();
  const toaster = useToaster();
  const { selectedFacility } = useSelectedFacility();

  const [dateFilter, setDateFilter] = useState<"all" | "history" | "upcoming">(
    "history",
  );
  const [markAsPaidState, setMarkAsPaidState] = useState<{
    unpaidObject: UnpaidObject | undefined;
    state: "idle" | "loading" | "completed";
    sendEmail: boolean;
  }>({
    unpaidObject: undefined,
    state: "idle",
    sendEmail: true,
  });
  const markedAsPaid = useRef<Set<Data["id"]>>(new Set());

  const { data, isLoading, error } = useSWR(
    selectedFacility?.id
      ? ["unpaidPagedResult", selectedFacility.id, dateFilter]
      : undefined,
    ([, facilityId, dateFilter]) =>
      getUnpaidPagedResult(facilityId, {
        pageSize: 10_000,
        date: "descending",
        dateFilter,
      }).then(response => response.result),
    {
      fallbackData: [],
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 10_000,
      onError: () => {
        toaster.toastError.unknown();
      },
    },
  );

  const { data: totalCount, mutate: mutateTotalCount } = useSWRImmutable(
    selectedFacility ? ["unpaidTotalCount", selectedFacility.id] : undefined,
    ([, selectedFacilityId]) =>
      getUnpaidTotalCount(selectedFacilityId, { dateFilter: "all" }),
  );
  const { cf } = useCurrencyFormat(
    totalCount?.totalSumUnpaidDocument.currencyCode,
  );

  const { sportTypes } = useFacilitySportTypes(selectedFacility?.id);

  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.user.name, {
        id: "name",
        sortDescFirst: false,
        sortingFn: "text",
        header: ({ column }) => (
          <button
            type="button"
            onClick={
              (column.getCanSort() && column.getToggleSortingHandler()) ||
              undefined
            }
            className="flex items-center gap-2"
          >
            <FormattedMessage id="common.name" />

            {column.getCanSort() && (
              <FontAwesomeIcon
                className={clsx(column.getIsSorted() && "text-primary")}
                icon={
                  column.getIsSorted()
                    ? column.getIsSorted() === "asc"
                      ? faSortAlphaAsc
                      : faSortAlphaDesc
                    : faArrowDownArrowUp
                }
              />
            )}
          </button>
        ),
        cell: props =>
          props.row.original.user.id ? (
            <Link to={adminGetPlayerPath(props.row.original.user.id)}>
              {props.getValue()}
            </Link>
          ) : (
            props.getValue()
          ),
      }),
      columnHelper.accessor(row => row.user.phone, {
        id: "phone",
        enableSorting: false,
        header: () => <FormattedMessage id="common.phone" />,
      }),
      columnHelper.accessor(row => row.user.email, {
        id: "email",
        enableSorting: false,
        header: () => <FormattedMessage id="common.email" />,
      }),
      columnHelper.accessor("sportType", {
        enableColumnFilter: true,
        filterFn: "weakEquals",
        header: ({ column }) => {
          return sportTypes ? (
            <Dropdown>
              <MenuButton
                slotProps={{
                  root: state => ({
                    className: clsx(
                      "group flex items-center gap-2",
                      (state.active || state.open || column.getIsFiltered()) &&
                        "is-active",
                    ),
                  }),
                }}
              >
                <FormattedMessage id="common.sport-type" />

                <FontAwesomeIcon
                  icon={faBarsFilter}
                  className={clsx(
                    "rounded p-1 transition-colors group-hover:bg-gray-50 group-hover:text-primary group-[.is-active]:bg-gray-50 group-[.is-active]:text-primary",
                  )}
                />
              </MenuButton>
              <Menu className="w-44 rounded bg-white px-0 py-4 text-sm font-semibold">
                {sportTypes.map(sportType => {
                  if (!sportType.name) {
                    return null;
                  }

                  return (
                    <MenuItem
                      key={sportType.id}
                      className={clsx(
                        "flex cursor-pointer items-center justify-between rounded-none px-4 py-2 text-pureblack transition-colors hover:!bg-blue-50",
                        column.getFilterValue() === sportType.name &&
                          "!bg-blue-50",
                      )}
                      onClick={() =>
                        column.setFilterValue(
                          column.getFilterValue() === sportType.name
                            ? undefined
                            : sportType.name,
                        )
                      }
                    >
                      <SportTypeTranslatedName sportType={sportType.name} />

                      {column.getFilterValue() === sportType.name && (
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faCheck}
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Dropdown>
          ) : (
            <FormattedMessage id="common.sport-type" />
          );
        },
      }),
      columnHelper.accessor("activityName", {
        enableSorting: false,
        header: () => <FormattedMessage id="common.activity" />,
      }),
      columnHelper.accessor("activityDate", {
        header: ({ column }) => (
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={column.getToggleSortingHandler()}
          >
            <FormattedMessage id="common.date" />
            <FontAwesomeIcon
              className={clsx(column.getIsSorted() && "text-primary")}
              icon={
                column.getIsSorted()
                  ? column.getIsSorted() === "asc"
                    ? faSortAsc
                    : faSortDesc
                  : faSort
              }
            />
          </button>
        ),
        cell: props => {
          const value = props.getValue();

          if (!value || !selectedFacility?.id) {
            return null;
          }

          return (
            <TableDateCell
              value={value}
              dateFormat={luxonDateFormat}
              facilityId={selectedFacility.id}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.amount.valueInclTax, {
        id: "amount",
        enableSorting: false,
        header: () => (
          <div className="text-right">
            <FormattedMessage id="common.amount.price" />
          </div>
        ),
        cell: props => (
          <TableCurrencyCell
            value={props.getValue()}
            currency={selectedFacility?.localization?.currencyCode}
            options={{ minimumFractionDigits: 2 }}
          />
        ),
      }),
      columnHelper.accessor(row => row.amount.tax, {
        id: "tax",
        enableSorting: false,
        header: () => (
          <div className="text-right">
            <FormattedMessage id="receipts.tax.message" />
          </div>
        ),
        cell: props => (
          <TableCurrencyCell
            value={props.getValue()}
            currency={selectedFacility?.localization?.currencyCode}
            options={{ minimumFractionDigits: 2 }}
          />
        ),
      }),
      columnHelper.display({
        id: "paymentStatus",
        header: () => <FormattedMessage id="common.payment.status" />,
        cell: props => (
          <div className="flex items-center gap-12">
            <div className="flex items-center gap-2">
              <span className="block size-2 rounded-full bg-red-600" />
              <FormattedMessage id="bookings.not-payed" />
            </div>

            <Dropdown>
              <MenuButton className="flex items-center">
                <FontAwesomeIcon icon={faEllipsis} />
              </MenuButton>

              <Menu>
                <MenuItem
                  disabled={!props.row.original.allowMarkAsPaid}
                  onClick={() =>
                    setMarkAsPaidState(v => ({
                      ...v,
                      state: "idle",
                      unpaidObject: props.row.original,
                    }))
                  }
                >
                  <FormattedMessage id="common.mark-as-paid" />
                </MenuItem>
              </Menu>
            </Dropdown>
          </div>
        ),
      }),
    ],
    [
      selectedFacility?.id,
      selectedFacility?.localization?.currencyCode,
      sportTypes,
    ],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [
        {
          id: "activityDate",
          desc: true,
        },
      ],
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
  });

  const [isPending, startTransition] = useTransition();

  const CustomRowComponent = ({ row }: { row: Row<Data> }) => {
    if (markedAsPaid.current.has(row.original.id)) {
      return (
        <tr className="[&>td]:border-l-2 [&>td]:border-l-transparent first:[&>td]:border-0 [&>td]:even:border-l-purewhite [&>td]:even:bg-white">
          <td className="p-4 text-center font-bold" colSpan={100}>
            <FormattedMessage id="admin.marked-as-paid" />
          </td>
        </tr>
      );
    }

    return <TableDefaultRowComponent row={row} />;
  };

  const filteredAmount = table.getFilteredRowModel().rows.reduce((acc, row) => {
    if (!row.original.amount) {
      return acc;
    }

    if (markedAsPaid.current.has(row.original.id)) {
      return acc;
    }

    return acc + row.original.amount.valueInclTax;
  }, 0);

  return (
    <>
      <div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="space-y-2">
            <h3>
              <FormattedMessage id="admin.facility.settings.economy.unpaid.title" />{" "}
              <span className="font-normal">
                ({isLoading ? "..." : data.length})
              </span>
            </h3>
            <p className="">
              <b>
                <FormattedMessage id="admin.facility.settings.economy.unpaid.total-expected-revenue" />
                :
              </b>{" "}
              {typeof totalCount !== "undefined" ? (
                <>
                  {cf(totalCount?.totalSumUnpaidDocument.valueInclTax)} (
                  <FormattedMessage id="common.incl-vat" />)
                </>
              ) : (
                "..."
              )}
            </p>
            <p className="text-primary">
              <b>
                <FormattedMessage id="admin.facility.settings.economy.unpaid.total-of-filtered-values" />
                : {!isLoading ? cf(filteredAmount) : "..."}
              </b>
            </p>
          </div>

          <SelectInput
            disabled={isLoading || isPending}
            className="text-sm"
            options={[
              {
                label: formatMessage({
                  id: "common.all",
                }),
                value: "all",
              },
              {
                label: formatMessage({ id: "common.upcoming" }),
                value: "upcoming",
              },
              {
                label: formatMessage({ id: "common.history" }),
                value: "history",
              },
            ]}
            value={dateFilter}
            onChange={e => {
              startTransition(() => {
                table.reset();
                setDateFilter(e.value);
              });
            }}
          />
        </div>

        <div className="mt-4 text-sm sm:mt-8">
          <Table
            table={table}
            isLoading={isLoading}
            RowComponent={CustomRowComponent}
          />

          {!isLoading && !error && table.getRowCount() !== 0 && (
            <div className="mt-16 flex justify-center">
              <Button
                size="small"
                type="primary"
                disabled={!table.getCanNextPage()}
                onClick={() => table.setPageSize(v => v + PAGE_SIZE)}
              >
                <FormattedMessage id="common.show.more" />
              </Button>
            </div>
          )}
        </div>
      </div>

      {markAsPaidState.state !== "completed" &&
        markAsPaidState.unpaidObject && (
          <ConfirmationDialog
            visible
            title={formatMessage({ id: "admin.markAsPaid.confirm.title" })}
            text={
              <FormattedMessage
                id="admin.markAsPaid.confirm.description"
                values={{
                  Bold: chunk => <b>{chunk}</b>,
                  name: markAsPaidState.unpaidObject.user.name,
                }}
              />
            }
            loading={markAsPaidState.state === "loading"}
            onHide={() =>
              setMarkAsPaidState(v => ({
                ...v,
                state: "idle",
                unpaidObject: undefined,
              }))
            }
            onCancel={() =>
              setMarkAsPaidState(v => ({
                ...v,
                state: "idle",
                unpaidObject: undefined,
              }))
            }
            onSubmit={async () => {
              if (!markAsPaidState.unpaidObject || !selectedFacility) {
                return;
              }

              setMarkAsPaidState(v => ({
                ...v,
                state: "loading",
              }));

              try {
                await markUnpaidObjectAsPaid(
                  selectedFacility.id,
                  markAsPaidState.unpaidObject.id,
                  markAsPaidState.sendEmail,
                );
                toaster.toastSuccess.message("admin.marked-as-paid");
                setMarkAsPaidState(v => ({
                  ...v,
                  state: "completed",
                  sendEmail: true,
                }));
                markedAsPaid.current.add(markAsPaidState.unpaidObject.id);
                mutateTotalCount(undefined);
              } catch (e) {
                console.error(e);
                toaster.toastError.unknown();

                setMarkAsPaidState(v => ({
                  ...v,
                  state: "idle",
                }));
              }
            }}
          >
            <div className="mt-5 flex items-center justify-center gap-5">
              <label htmlFor="sendEmail">
                <FormattedMessage id="admin.booking.send-confirmation-email" />
              </label>
              <InputSwitch
                inputId="sendEmail"
                checked={markAsPaidState.sendEmail}
                onChange={e =>
                  setMarkAsPaidState(v => ({
                    ...v,
                    sendEmail: e.value,
                  }))
                }
              />
            </div>
          </ConfirmationDialog>
        )}
    </>
  );
};
