import { useMemo } from "react";

import type {
  FacilityWithUtc,
  SportType,
} from "../modules/customer/models/Facility";

import { useFacility } from "./swr/useFacility";

const sportTypes = new Map();

export const useFacilitySportTypes = (
  facilityId: FacilityWithUtc["id"] | null | undefined,
): { sportTypes: SportType[] } => {
  const { facility } = useFacility(facilityId);

  return useMemo(() => {
    if (sportTypes.size > 0) {
      return { sportTypes: Array.from(sportTypes.values()) };
    }

    if (!facility) {
      return { sportTypes: [] };
    }

    facility.bookableEntityTypes?.forEach(entityType => {
      if (!entityType.sportType || sportTypes.has(entityType.sportType?.id)) {
        return;
      }

      sportTypes.set(entityType.sportType?.id, entityType.sportType);
    });

    return { sportTypes: Array.from(sportTypes.values()) };
  }, [facility]);
};
